.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2vh;
}

.leftCol {
    width: 15vw;
}

.rightCol {
    width: 15vw;
}

.mainCol {
    width: 70vw;
}

.leftColSidebarOpen {
    margin-left: 240px;
    width: calc(15vw - (240px / var(--viewport-width)));
}

.rightColSidebarOpen {
    width: calc(15vw - (240px / var(--viewport-width)));
}

@media only screen and (max-width: 720px) {
    .leftCol {
        width: 0px;
    }
    
    .rightCol {
        width: 0px;
    }

    .mainCol {
        width: 100vw;
    }
}