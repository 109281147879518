.container {
  display: flex;
  align-items: start;
  flex-flow: column nowrap;
  width: 100%;
  height: 100px;
  padding-bottom: 8px;
  background-color: #FFFFFF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  border-bottom: 1px solid #D6D6D6;
}

.replyCommentHeader {
  background-color: #F0F1F6;
  width: 100%;
  height: 30px; /* Set fixed height */
  display: flex;
  justify-content: space-between;
  padding-block: 0.25rem;
  padding-inline-start: 1.5rem;
  padding-inline-end: 0.5rem;
  align-items: center;
  border: 1px solid #D6D6D6;
  border-radius: 10px 10px 0 0;
}

.replyCommentTitle p {
  font-size: 12px;
  color: #385282;
  font-weight: 500;
  margin-bottom: 0;
}

.replyCommentCancel {
  cursor: pointer;
  border: none;
  outline: none;
}

.replyCommentContainer {
  display: flex;
  padding-inline: 0.5rem;
  padding-block-start: 0.75rem;
}

.replyCommentPhoto {
  margin-right: 6px;
}

.replyCommentMessage {
  width: 100%;
  height: 53px; /* Set fixed height */
  overflow: hidden; /* Hide overflow content */
}

.replyCommentMessage h5 {
  font-size: 14px;
  color: #708FC7;
  font-weight: bold;
  margin-bottom: 3px;
}

.replyCommentMessage p {
  font-size: 12px;
  color: #708FC7;
  font-weight: 500;
}

.attachmentIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.attachmentTitle {
  text-align: left;
}

.photo {
  margin-right: 5px;
}

.photo > div {
  margin: 0 !important;
}

.photo > div > img {
  width: 38px !important; 
  height: 38px !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}

.attachment {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-bottom: 3px;
}

.attachment:hover {
  opacity: 0.8;
}

.attachment > svg {
  width: 32px;
  color: #7A7A7A;
}

.text {
  min-height: 32px;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 20px;
  width: 62vw;
  margin-right: 20px;
  margin-left: 10px;
  padding: 2px 12px 0px 12px;
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
}

.text > textarea {
  width: 100%;
  margin-right: 5px;
  position: relative;
  outline: 0;
  border: 0;
  resize: none;
}

.text :global(.fr-wrapper)::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
.text :global(.fr-wrapper)::-webkit-scrollbar-thumb {
border-radius: 20px;
background-color: #c4c4c4;
}

.text :global(.fr-wrapper)::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.send {
  width: 51px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 3px;
}

.send:hover {
  opacity: 0.8;
}

.send > svg {
  width: 32px;
  color: #FDC532;
}