.mainSection {
    position: absolute;
    background-color: white;
    display: block;
    top: 50%;
    border: 1px solid black;
    min-width: 60%;
    padding: 2px;
    z-index: 1;
    max-height: 50vh;
    min-height: 50px;
    overflow: auto;
}

.listSection {
    cursor: pointer;
}

.listSection:hover {
    background-color: lightgrey;
}