.container {
    display: flex;
    align-items: center;
}

.deleteIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    padding: 2px;
    background-color: lightgrey;
    margin-right: 1rem;
    cursor: pointer;
}

.deleteIcon > svg {
    width: 18px;
}