.container {
    position: fixed;
   
    z-index: 1000;
  }

  .chat {
    bottom: 70px;
    right: 20px;
  }

  .groupChat {
    bottom: 70px;
    right: 310px;
  }
  
  .button {
    background-color: #FDC532;
    height: 40px;
    width: 40px;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button:hover {
    opacity: 0.8;
  }
  
  .button > svg {
    width: 24px;
  }