.container {
  display: flex;
  align-items: flex-end;
  height: auto;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  min-height: 55px;
  padding-bottom: 8px;
}

.attachment {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-bottom: 3px;
}

.attachment:hover {
  opacity: 0.8;
}

.attachment > svg {
  width: 32px;
  color: #7A7A7A;
}

.text {
  min-height: 32px;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 20px;
  width: 62vw;
  margin-right: 20px;
  margin-left: 10px;
  padding: 2px 12px 0px 12px;
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
}

.audioTumbnail {
  min-height: 32px;
  height: auto;
  /* background: #FFFFFF;
  border: 1px solid #ECECEC; */
  /* box-sizing: border-box; */
  /* border-radius: 20px; */
  width: 62vw;
  margin-right: 20px;
  margin-left: 10px;
  padding: 2px 12px 0px 12px;
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
}

.text > textarea {
  width: 100%;
  margin-right: 5px;
  position: relative;
  outline: 0;
  border: 0;
  resize: none;
}

.text :global(.fr-wrapper)::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
.text :global(.fr-wrapper)::-webkit-scrollbar-thumb {
border-radius: 20px;
background-color: #c4c4c4;
}

.text :global(.fr-wrapper)::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.send {
  width: 51px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 3px;
}

.send:hover {
  opacity: 0.8;
}

.send > svg {
  width: 32px;
  color: #FDC532;
}

.audioRecorder {
  margin-left: auto;
}