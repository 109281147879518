.content {
    display: flex;
    height: 62vh;
    max-height: 62vh;
    overflow-y: auto;
}

.content::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.content::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.content::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.recentSection {
    width: 50%;
}

.hqteamSection {
    width: 40%;
    max-height: 1000px;
    text-wrap: wrap;
}

.projectSection {
    width: 40%;
}

@media only screen and (max-width: 1024px) {
    .content {
        width: auto;
        display: block;
    }

    .recentSection {
        width: 100%;
        margin-bottom: 1.2rem;
    }
    
    .hqteamSection {
        width: 100%;
        margin-bottom: 1.2rem;
    }
    
    .projectSection {
        width: 100%;
    }
}

@media only screen and (max-width: 720px) {
    .content {
        width: auto;
        display: block;
    }

    .recentSection {
        width: 100%;
        margin-bottom: 1.2rem;
    }
    
    .hqteamSection {
        width: 100%;
        margin-bottom: 1.2rem;
    }
    
    .projectSection {
        width: 100%;
    }
}