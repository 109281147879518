.container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 0.2rem;
    padding-right: 0.4rem;
    background-color: white;
}

.containerMultiline {
    padding-left: 0.3rem;
    padding-right: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.input {
    width: 100%;
    position: relative;
    outline: 0;
    border: 0;
    resize: none;
    padding: 2px;
    font-size: 12px;
    font-family: Nunito;
    box-sizing: border-box;
    padding: 2px 6px;
}
  
.input::placeholder {
    color: #D6D6D6;
}
  
.input::-webkit-input-placeholder {
    color: #D6D6D6;
}

.input::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.input::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.input::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

/* Border Variant */
.primary {
    border: 1px solid #4D4D4D;
}

.primaryFocus {
    border: 1px solid #4D4D4D;
}

.secondary {
    border: 1px solid #D6D6D6;
}

.secondaryFocus {
    border: 2px solid #D6D6D6;
}

.danger {
    border: 1px solid #FF7171;
}

.dangerFocus {
    border: 1px solid #FF7171;
}

.success {
    border: 1px solid #42E691;
}

.successFocus {
    border: 1px solid #42E691;
}

.helperIcon {
    margin-left: 0.1rem;
    cursor: pointer;
}

.helperIconPrimaryColor > svg {
    color: #D6D6D6
}

.helperIconSecondaryColor > svg {
    color: #FFE49B
}

.helperIconDangerColor > svg {
    color: #FF7171
}

.helperIconSuccessColor > svg {
    color: #42E691
}

.helperIconSizeSm > svg {
    width: 14px;
}

.helperIconSizeMd > svg {
    width: 18px;
}

.helperIconSizeLg > svg {
    width: 22px;
}