.container {
    position: relative;
    border: none;
    outline: none;
}

.container:hover, .container:focus {
    outline: none;
}

.container:hover {
    opacity: 0.8;
}

.iconText {
    display: flex;
    align-items: center;
}

.iconText > svg {
    width: 24px;
}

.iconPositionStart {
    flex-direction: row;
}

.iconPositionStart > svg {
    margin-right: 5px;
}

.iconPositionEnd {
    flex-direction: row-reverse;
}

.iconPositionEnd > svg {
    margin-left: 5px;
}

.iconPositionCenter {
    flex-direction: row;
}

.iconPositionCenter > svg {
    margin: 0;
}

.iconDefault > svg {
    color: #ffffff;
}
.iconSuccess > svg {
    color: #ffffff;
}
.iconDanger > svg {
    color: #ffffff;
}

.iconBlueAquamarine > svg {
    color: #ffffff;
}
.iconLight > svg {
    color: #B5B5B5;
}

.iconGrayLight > svg {
    color: white;
}
.iconBlueLight > svg {
    color: #385282;
}

.iconGreenLight > svg {
    color: #33CB7C;
}
.iconOutlinedDefault > svg {
    color: #FDC532;
}
.iconOutlinedSuccess > svg {
    color: #33CB7C;
}
.iconOutlinedDanger > svg {
    color: #FF7171;
}

.iconXs > svg {
    width: 12px;
}

.iconSm > svg {
    width: 14px;
}

.iconMd > svg {
    width: 18px;
}

.iconLg > svg {
    width: 24px;
}

.spinner {
    margin-left: 10px;
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
}

.disabled {
    cursor: default;
    opacity: 0.8;
}