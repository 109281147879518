.container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.editBoxTrue {
    border: 1px solid #B5B5B5;
}

.editBoxFalse {}

  .launchIcon {
    display: inline-flex;
    margin-left: auto;
    position: absolute;
    top: 2px;
    right: 6px;
    /* z-index: 200; */
    padding: 2px;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 5px; 
    background-color: #B5B5B5;
  }
  
  .launchIcon > svg {
    color: white;
    width: 18px;
  }

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .titleIcon {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .othersPropertiesSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .othersPropertiesSection p {
    font-size: 0.7rem;
      white-space: pre-wrap;
      word-wrap: break-word;
  }