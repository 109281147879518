.container {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}
.image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
}