.companiesCountSection {
    box-sizing: border-box;
    /* width: 279px; */
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
    padding-left: 0.6rem;
}