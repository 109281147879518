.container {
    width: 34px;
    height: 34px;
    padding: 2px;
    border-radius: 100%;
    background-color: #FFEEC3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 2px;
}

.container:hover {
    opacity: 0.8;
}

.buttonCheers {
    width: 28px;
    height: 28px;
    padding: 2px;
    border-radius: 100%;
    background-color: white;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonCheers > img {
    width: 20px;
}

.createMode {
    box-sizing: border-box;
    width: 248px;
    height: 45px;
    background: #FFFFFF;
    position: relative;
    border: 1px solid #ECECEC;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 0.8rem;
    padding-right: 0.7rem;
}

.content {
    width: 50%;
    margin-right: 15px;
}

.content input {
    border: 0;
    box-shadow: none;
    outline: 0;
    font-size: 0.8rem;
    padding: 0;
    margin-left: 4px;
}

.content input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.action {
    display: flex;
}

.action svg {
    width: 24px;
    cursor: pointer;
    margin-right: 2px;
}

.action svg:hover {
    opacity: 0.8;
}

.checkIcon {
    color: #42E691;
}

.cancelIcon {
    color: #FF7171;
}

.emojiPicker {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 2;
  }