.container {
    /* display: flex; */
}

.photo {
    margin-right: 10px;
}

.date {
    display: flex;
}

.photoAndUser {
    display: flex;
    margin-bottom: 20px;
}

.photo {
    margin-right: 5px;
}