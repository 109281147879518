.date {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.comment {
    width: 100%;
}

.photo {
    display: flex;
    margin-right: 5px;
}