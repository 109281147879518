.container {
    position: relative;
}

.value {
    top: -5px;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}