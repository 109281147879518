.container {
    max-height: 500px;
    overflow-y: auto;
    height: 500px;
    background-color: white;
    /* padding: 0px 0px 12px 0px; */
    overflow-x: hidden;
}

.container::-webkit-scrollbar {
    width: 6px;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c4c4c4;
}
  
.container::-webkit-scrollbar-thumb:hover {
    background: #d1d1d1;
}

.item {
    cursor: pointer;
}