.headerPost {
    display: flex;
    width: max-content;
}

.item {
    width: 100%;
}

.photo {
    margin-right: 10px;
}

.date {
    display: flex;
}

.titleAndDate {
    display: flex;
    align-items: center;
    width: 100%;
}

.title {
    margin-right: 10px;
    width: 100%;
}