.container {
    display: flex;
    align-items: center;
    padding-left: 2px;
    padding-right: 2px;
    margin-bottom: 8px;
    margin-top: 8px;
    cursor: pointer;
}

.container:hover {
    opacity: 0.8;
}

.icon {
    margin-right: 8px;
    width: 24px;
}

.iconDefault > svg {
    color: #708FC7;
}
.iconSuccess > svg {
    color: #33CB7C;
}
.iconDanger > svg {
    color: #FF7171;
}

.iconBlueAquamarine > svg {
    color: #ffffff;
}
.iconLight > svg {
    color: #B5B5B5;
}
.iconBlueLight > svg {
    color: #DEEAFF;
}
.iconWarning > svg {
    color: #FDC532;
}