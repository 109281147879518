.container {
    /* width: 279px; */
    height: 49px;
    /* white */
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.2rem;
    padding-right: 0.5rem;
}

.companySection {
    display: flex;
    align-items: center;
}

.logo {
    margin-right: 4px;
}

.selected {
    background-color: #FFEEC3;
}

.notSelected:hover {
    background-color: #f7f7f7;
}

.indicatorSection {
    display: flex;
    align-items: center;
}

.indicatorSection > svg {
    width: 24px;
    cursor: pointer;
    margin-right: 2px;
}

.expiredIcon {
    color: #FF7171;
}

.soonIcon {
    color: #FFD974;
}