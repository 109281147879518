.DateBox {
    padding-right: 6px;
    padding-left: 6px;
    background-color: #ebecf1;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  
  .DateBox__overdue {
    background-color: #FF7171;
  }
  
  .DateBox__overdue p, .DateBox__overdue time {
    color: white;
  }
  
  .DateBox__overdue svg {
    color: white;
  }
  
  .noClick {
    cursor: inherit;
  }
  
  .DateBox p, .DateBox time {
    font-size: 0.7rem;
    margin-bottom: 0;
    line-height: 1;
  }
  
  .DateBox svg {
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }
  
  .DateBox__smallTitle {
    color: #263238;
    font-size: 0.8rem;
  }
  
  .expandIcon {
    margin-left: 5px;
  }
  
  .whiteStyle {
    color: white;
  }
  
  .redStyle {
    color: #FF7171;
  }