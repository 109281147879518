.containerModal {
  padding: 1rem;
}

.container {
padding: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.ManageDueDateContainer__Header {
  display: flex;
  align-items: center;
}

.ManageDueDateContainer__Header h1 {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.ManageDueDateContainer__Header svg {
  cursor: pointer;
}

.body {
  /* display: flex; */
  margin-top: 10px;
}

.datePicker {
  margin-bottom: 15px;
  /* flex-grow: 1;
  margin-right: 10px; */
}
.timePicker {
  /* flex-grow: 1; */
}

.muiPicker {
  margin-top: 0;
}

.muiKeyboardDatePicker {
  margin-top: 0;
  font-size: 0.7rem;
}


.footer {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}


.checklistAndTitle {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.checklist {
  margin-right: 0.5rem;
  cursor: pointer;
}

.timePicker {
  margin-top: 0.5rem;
}