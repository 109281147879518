.container {
  display: flex;
  max-width: 80%;
  /* margin-bottom: 20px; */
}

.balloon {
  
}

.balloonAndVector {
  /* display: flex;
  position: relative; */
}

.balloonReplyTo {
  cursor: pointer;
  background-color: #FFFFFF;
  padding-block-start: 4px;
  padding-block-end: 12px;
  padding-inline: 12px;
  border-radius: 10px 10px 0 0;
  /* width: auto;  */
  margin-bottom: -5px;
}

.balloonReplyToPositionSelf {
  /* max-width: 98%; */
}

.balloonReplyToPositionOther {
  /* max-width: calc(100% - 15px); */
}

.balloonReplyToTitle .balloonReplyToTitleText {
  font-size: 12px;
  color: #708FC7;
  font-weight: bold;
}

.balloonReplyToContent {
  background-color: #F0F1F6;
  padding-block: 5px;
  padding-inline: 8px;
  border-radius: 10px;
}

.balloonReplyToContent div > p {
  color: #7A7A7A !important;
  font-weight: normal !important;
}

.content {
  padding: 10px 1rem 30px 1rem;
  position: relative;
  max-width: 40vw;
}

.vectorBalloon {
  background: #ffffff;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  width: 24px;
  height: 16px;
  margin-right: -1px;
  z-index: 2;
  top: 0px;
  left: -15px;
  position: absolute;
}

.vectorBalloonSelf {
  background: #FFEEC3;
  clip-path: polygon(0 0, 100% 0, 0% 100%);
  width: 24px;
  height: 16px;
  margin-left: -1px;
  z-index: 2;
  position: absolute;
  top: 0px;
  right: -24px;
}

.balloonMessage {
  background: #FFFFFF;
  border-radius: 0px 10px 10px 10px;
  min-height: 90px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 95%;
  min-width: 270px;
  position: relative;
}

.name {
  margin-left: 18px;
}

.timeStamp {
  position: absolute;
  bottom: 5px;
  right: 3px;
}

.seen {
  position: absolute;
  bottom: 3px;
  left: 7px;
}

.sentIcon {
  position: absolute;
  bottom: 5px;
  right: -1.3rem;
}

.photo {
  margin-right: 5px;
}

.photo > div {
  margin: 0 !important;
}

.photo > div > img {
  width: 38px !important; 
  height: 38px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.name > h1 {
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
}

.attachment__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.attachment__title {
  text-align: center;
}

.attachment__title > p {
  margin-bottom: 10px !important;
}

.balloonMessage:hover > .iconOption {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 8px;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background-color: #fafafa;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}


.iconOption { 
  display: none;
}

.iconOptionDeleted { 
  display: none;
}

.iconOption > svg {
  width: 24px;
}

.playIcon > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50px;
  left: 130px;
  color: white;
}

.cheers {
  min-height: 80px;
  /* max-width: 90%; */
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 2rem;
  /* margin-bottom: 20px; */
}

.matchQuery {
  background-color: #FFE49B;
  border-radius: 10px;
}

.unmatchQuery {

}